import * as React from 'react';
import { ClipLoader } from 'react-spinners';

interface LoaderProps {
  isLoading: boolean;
}

export default class Loader extends React.Component<LoaderProps, {}> {
  render() {
    return <React.Fragment>
      {this.props.isLoading &&
        <div className="loader-container">
          <ClipLoader color={'#5e8edb'} />
        </div>
      }
    </React.Fragment>;
  }
}
