import axios from 'axios';
import * as React from 'react';
import { Route, withRouter, RouteComponentProps, Switch } from 'react-router-dom';
import {
  FormGroup,
  Nav,
} from 'reactstrap';

import RNav from './RNav';
import Client from '../interfaces/Client';
import SurveysList from './SurveysList';
import ClientSettingsComponent from './ClientSettingsComponent';
import Loader from './Loader';

interface ClientViewState {
  isLoading?: boolean;
  client?: Client;
  errorMessage?: string;
}

interface ClientMatchParams {
  clientId?: string;
}

interface ClientViewProps extends RouteComponentProps<ClientMatchParams> {
  onClientDeleted: () => void;
}

interface ClientApiResponse {
  client: Client;
}

class ClientView extends React.Component<ClientViewProps, ClientViewState> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }
  async componentDidUpdate(oldProps: ClientViewProps) {
    if (oldProps.match.params.clientId !== this.props.match.params.clientId) {
      await this.refreshClient();
    }
  }
  async componentDidMount() {
    await this.refreshClient();
  }
  async refreshClient() {
    this.setState({ isLoading: true });
    try {
      const { clientId } = this.props.match.params;
      const url = `/api/clients/${clientId}`;
      const { client } = (await axios.get<ClientApiResponse>(url)).data;
      this.setState({ client, isLoading: false });
    } catch (err) {
      const errorMessage = 'dagag';
      this.setState({ errorMessage, isLoading: false });
    }
  }
  onClientUpdate = async () => {
    await this.refreshClient();
  }
  render() {
    const { match } = this.props;
    const { client, isLoading, errorMessage } = this.state;
    return <div>
      <Loader isLoading={isLoading} />
      {client && !isLoading &&
        <React.Fragment>
          <FormGroup>
            <Nav tabs>
              <RNav path={`${match.url}/surveys`}>
                Surveys
              </RNav>
              <RNav path={`${match.url}/settings`}>
                Client settings
              </RNav>
            </Nav>
          </FormGroup>
          <FormGroup>
            <Switch>
              <Route
                path={`${match.path}/surveys`}
                component={SurveysList}
              />
              <Route
                path={`${match.path}/settings`}
                render={props =>
                  <ClientSettingsComponent
                    {...props}
                    onClientUpdate={this.onClientUpdate}
                    onClientDeleted={this.props.onClientDeleted}
                    client={client}
                  />
                }
              />
            </Switch>
          </FormGroup>
        </React.Fragment>
      }
    </div >;
  }
}

export default withRouter(ClientView);
