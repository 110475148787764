import * as React from 'react';
import './BarGraphDisplay.css';
import Bar from '../Bar';
import BarGraph from '../BarGraph';
import BarSegment from '../BarSegment';
import classnames from 'classnames';
import smooth from './smooth';
import { Col } from 'reactstrap';
import segmentGradients from '../segmentGradients';

interface BarGraphDisplayProps {
  /**
   * The height of the control
   */
  height: number;
  /**
   * The bar graph data
   */
  barGraph: BarGraph;
}

interface BarComponentProps {
  active: boolean;
  bar: Bar;
  barGraph: BarGraph;
  height: number;
}

/**
 * Represents a bar in a bar graph
 * @param props
 */
const BarComponent: React.FC<BarComponentProps> = (props: BarComponentProps) => {
  const { height, bar, barGraph, active } = props;
  const { format, decimalPlaces } = barGraph;
  const { segments } = bar;
  const total = segments.reduce((t, s) => t + s.value, 0);
  const classes = classnames(['bar-graph-bar', { inactive: !active }]);
  return (
    <div className="bar-graph-bar-container">
      <div
        className={classes}
        style={{
          top: `${100 - height * 100}%`,
        }}>
        {segments.length > 1 &&
          <div className="bar-graph-bar-label">
            <span>{total.toFixed(decimalPlaces)}{format}</span>
          </div>
        }
        {segments.map((s, i) =>
          <BarSegmentComponent
            key={i}
            barGraph={barGraph}
            segment={s}
            height={s.value / total}
          />,
        )}
      </div>
    </div>
  );
};

interface BarSegmentComponentProps {
  barGraph: BarGraph;
  /**
   * The height of the segment within the bar (0.0 - 1.0)
   */
  height: number;
  /**
   * The segment
   */
  segment: BarSegment;
}

class BarSegmentComponent extends React.Component<BarSegmentComponentProps> {
  render() {
    const { height, segment, barGraph } = this.props;
    const { decimalPlaces, format } = barGraph;
    return (
      <div
        className={`bar-graph-bar-segment`}
        style={{
          height: `${(height * 100).toFixed(2)}%`,
        }}>
        <svg
          className="bar-graph-segment-vector"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            {
              Object
                .entries(segmentGradients)
                .map(([key, [startColor, endColor]], index) => (
                  <linearGradient
                    id={`segment-gradient-${key}`}
                    key={index}
                    x1="0%" y1="0%" x2="100%" y2="0%"
                    gradientTransform="rotate(90)"
                  >
                    <stop offset="0%" style={{ stopColor: startColor as string, stopOpacity: 1 }} />
                    <stop offset="100%" style={{ stopColor: endColor as string, stopOpacity: 1 }} />
                  </linearGradient>
                ))
            }
          </defs>
          <rect width="100" height="100" fill={`url(#segment-gradient-${segment.color || 'blue'})`} />
        </svg>
        <div className="bar-graph-bar-segment-label">
          <span>{segment.value.toFixed(decimalPlaces)}{format}</span>
        </div>
      </div>
    );
  }
}

function getTrendColor(firstTotal: number, lastTotal: number) {
  return firstTotal > lastTotal
    ? 'red'
    : firstTotal === lastTotal
      ? 'yellow'
      : 'green';
}

interface BarGraphDisplayState {
  active: boolean;
  height: number;
}

export default class BarGraphDisplay extends React.Component
  <BarGraphDisplayProps, BarGraphDisplayState>
{
  containerRef = React.createRef<HTMLDivElement>();
  constructor(props: BarGraphDisplayProps) {
    super(props);
    this.state = { active: false, height: 500 };
  }
  componentDidMount() {
    const height = 380; // this.containerRef.current.clientWidth;
    this.setState({ height, active: true });
  }
  render() {
    const { barGraph } = this.props;
    const { active, height } = this.state;
    const { min, max, steps, bars, format, title, trend, decimalPlaces, width } = barGraph;
    const intervals = Array(steps + 1)
      .fill(null)
      .map((n, i) => min + (max - min) / steps * i)
      .reverse();
    const barTotals = bars.map(b => b.segments.reduce((t, s) => s.value + t, 0));
    const barTotalsAsPercent = barTotals.map(bt => (bt - min) / (max - min));
    const trendColor = trend === 'color'
      ? getTrendColor(barTotals[0], barTotals[barTotals.length - 1])
      : 'black';
    console.log(title, trend, trendColor);
    const intervalHeight = `${100 / (steps)}% `;
    const colSize = width === 'full' ? 12 : 6;
    const scaledWidth = width === 'full' ? 200 : 100;
    return (
      <Col xs={colSize} md={colSize} sm={colSize} lg={colSize}>
        <div className={classnames('bar-graph-container', { 'bar-graph-container-full-width': width === 'full' })}>
          <div className="bar-graph">
            <div className="bar-graph-cols">
              <div className="bar-graph-left bar-graph-upper-section">
                <div className="bar-graph-legend-y">
                  {intervals.map(i => (
                    <div
                      className="bar-graph-interval-container"
                      style={{ height: intervalHeight }}
                      key={i}>
                      <div className="bar-graph-interval-label">
                        {i.toFixed(decimalPlaces)}{format}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="bar-graph-inner" ref={this.containerRef}>
                <div className="bar-graph-display">
                  <div
                    className="bar-graph-interval-line-container"
                    style={{
                      top: `-${intervalHeight}`,
                      bottom: `-${intervalHeight}`,
                    }}>
                    {Array(steps + 2).fill(null).map((_n, i) => (
                      <div
                        className="bar-graph-interval-line"
                        style={{ height: `${100 / (steps + 2)}%` }}
                        key={i}
                      />
                    ))}
                  </div>
                  <div className="bar-graph-bar-column-container">
                    {bars.map((bar, barIndex) => {
                      const height = barTotalsAsPercent[barIndex];
                      return (
                        <div
                          key={barIndex}
                          className="bar-graph-bar-column"
                          style={{ width: `${100 / bars.length}%` }}>
                          <BarComponent
                            barGraph={barGraph}
                            bar={bar}
                            height={height}
                            active={active} />
                        </div>
                      );
                    })}
                  </div>
                  {trend !== undefined &&
                    <div className="bar-graph-trendline-container">
                      <svg
                        preserveAspectRatio="none"
                        className="bar-graph-trendline"
                        viewBox={`0 0 ${scaledWidth} 100`}
                      >
                        <path
                          d={(smooth as (arg: any) => string)(bars.map((bar, barIndex) => [
                            scaledWidth / bars.length / 2 + scaledWidth / bars.length * barIndex,
                            100 - barTotalsAsPercent[barIndex] * 100,
                          ]))}
                          strokeDasharray="4 2"
                          stroke={trendColor}
                          fill="transparent" />
                      </svg>
                    </div>
                  }
                </div>
                <div className="bar-graph-lower">
                  <div className="bar-graph-legend-x">
                    {bars.map((bar, i) => (
                      <div
                        key={i}
                        className="bar-graph-label-container"
                        style={{ width: `${100 / bars.length}%` }}>
                        <div className="bar-graph-label">
                          {bar.label}
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="bar-graph-title">
                    <legend>{title}</legend>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Col>
    );
  }
}
