import * as React from 'react';
import { Switch, Route, withRouter, RouteComponentProps } from 'react-router-dom';
import { Label, FormGroup, Nav } from 'reactstrap';

import Survey from '../interfaces/Survey';

import RNav from './RNav';
import SurveyRecipientsView from './SurveyRecipientsView';
import ManageAssetsComponent from './ManageAssetsComponent';
import ManageWebModuleParametersComponent from './ManageWebModuleParametersComponent';
import SurveySettings from './SurveySettings';
import SurveyPopiView from './SurveyPopiView/SurveyPopiView';
import SurveyNotificationsView from './SurveyNotificationsView/SurveyNotificationsView';
import ReportPreview from './ReportPreview';

interface SurveyViewState {
}

interface SurveyViewRouteProps {
  surveyId: string;
}

interface SurveyViewProps extends RouteComponentProps<SurveyViewRouteProps> {
  survey: Survey;
  onSurveyUpdated: () => void;
  onSurveyDeleted: () => void;
}

class SurveyView extends React.Component<SurveyViewProps, SurveyViewState>
{
  constructor(props: SurveyViewProps) {
    super(props);
    this.state = {};
  }
  render() {
    const { match, survey, onSurveyUpdated, onSurveyDeleted } = this.props;
    return <React.Fragment>
      <FormGroup>
        <Nav tabs>
          <RNav path={`${match.url}/recipients`}>
            Recipients
          </RNav>
          <RNav path={`${match.url}/assets`}>
            Assets
          </RNav>
          <RNav path={`${match.url}/parameters`}>
            Parameters
          </RNav>
          <RNav path={`${match.url}/settings`}>
            Survey settings
          </RNav>
          <RNav path={`${match.url}/popi-data`}>
            POPI
          </RNav>
          <RNav path={`${match.url}/notifications`}>
            Notifications
          </RNav>
          <RNav path={`${match.url}/report`}>
            Report
          </RNav>
        </Nav>
      </FormGroup>
      <FormGroup>
        <Switch>
          <Route
            path={`${match.path}/recipients`}
            component={
              withRouter((props: RouteComponentProps<any>) =>
                <SurveyRecipientsView {...props} survey={survey} />,
              )
            }
          />
          <Route
            path={`${match.path}/assets`}
            component={() => <ManageAssetsComponent survey={survey} />
            } />
          <Route
            path={`${match.path}/parameters`}
            component={() => <ManageWebModuleParametersComponent survey={survey} />
            } />
          <Route
            path={`${match.path}/settings`}
            render={() =>
              <SurveySettings
                onSurveyUpdated={onSurveyUpdated}
                onSurveyDeleted={onSurveyDeleted}
                survey={survey}
              />
            }
          />
          <Route
            path={`${match.path}/popi-data`}
            render={() => <SurveyPopiView survey={survey} />}
          />
          <Route
            path={`${match.path}/notifications`}
            render={() => <SurveyNotificationsView survey={survey} />}
          />
          <Route
            path={`${match.path}/report`}
            component={ReportPreview}
          />
        </Switch>
      </FormGroup>
    </React.Fragment>;
  }
}

export default withRouter(SurveyView);
