import * as React from 'react';
import axios from 'axios';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Label,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import Client from '../interfaces/Client';
import Survey from '../interfaces/Survey';
import Loader from './Loader';

interface CloneSurveyModalProps {
  isOpen: boolean;
  wasClosed: () => void;
  wasSuccessful: () => void;
  className?: string;
  survey: Survey;
}

interface CloneSurveyModalState {
  clients: Client[];
  isLoading?: boolean;
  selectedClient: Client;
  dropdownIsOpen: boolean;
}

export default class CloneSurveyModal extends React.Component
  <
  CloneSurveyModalProps,
  CloneSurveyModalState
  >
{
  constructor(props: CloneSurveyModalProps) {
    super(props);
    this.state = {
      isLoading: false,
      selectedClient: null,
      dropdownIsOpen: false,
      clients: null,
    };
  }
  okClicked = async (e: React.MouseEvent) => {
    const { selectedClient } = this.state;
    const { survey } = this.props;
    const url = `/api/surveys/${survey.id}/clone`;
    const payload = { clientId: selectedClient.id };
    this.setState({ isLoading: true });
    try {
      await axios.post(url, payload);
      this.wasClosed();
    } catch (err) {
      console.error(err);
    }
    this.setState({ isLoading: false });
  }
  componentDidUpdate(oldProps: CloneSurveyModalProps) {
    if (this.props.isOpen && !oldProps.isOpen) {
      this.refreshClients();
    }
  }
  wasClosed = () => {
    this.props.wasClosed();
  }
  render() {
    const { isOpen, survey } = this.props;
    const wasClosed = this.wasClosed;
    const { isLoading, clients, dropdownIsOpen, selectedClient } = this.state;
    const okEnabled = selectedClient && !isLoading;
    return <div>
      <Modal isOpen={isOpen} toggle={wasClosed} className={this.props.className}>
        <ModalHeader toggle={wasClosed}>
          Clone {survey.name}
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label>
              Select a client to clone the survey to
            </Label>
            <Loader isLoading={isLoading} />
            {!isLoading && clients && clients.length > 0 &&
              <Dropdown
                isOpen={dropdownIsOpen}
                toggle={() => this.setState({ dropdownIsOpen: !dropdownIsOpen })}>
                <DropdownToggle caret>
                  {selectedClient && selectedClient.name || 'Select a client'}
                </DropdownToggle>
                <DropdownMenu>
                  {clients.map(client =>
                    <DropdownItem
                      onClick={() => this.setState({ selectedClient: client })}>
                      {client.name}
                    </DropdownItem>,
                  )}
                </DropdownMenu>
              </Dropdown>
            }
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={!okEnabled}
            color="primary"
            onClick={this.okClicked}>
            Clone
          </Button>{' '}
          <Button color="secondary" onClick={wasClosed}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>;
  }
  refreshClients = async () => {
    const response = await axios.get('/api/clients');
    const { clients } = response.data;
    this.setState({ clients, isLoading: false });
  }
}
