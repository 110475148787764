import * as React from "react";
import segmentGradients from "../segmentGradients";
import './TrendLegend.scss';
import classnames from 'classnames';

const ColorLegend: React.FC<{ color: keyof typeof segmentGradients, text: string }> = ({ color, text }) => {
  const sg = segmentGradients[color];
  const background = `linear-gradient(${sg[0]}, ${sg[1]})`;
  return (
    <div className="color-legend-row">
      <div className="color-legend-block mr-2" style={{ background }} />
      <span className="color-legend-text">{text}</span>
    </div>
  )
};

const TrendLegend: React.FC<{ className?: string, fixed?: boolean, style?: React.CSSProperties }> = ({ className, fixed, style }) => {
  const cn = classnames(className, 'color-legend', { 'color-legend-fixed': fixed })
  return (
    <div className={cn} style={{ ...style }}>
      <div className="color-legend-contents">
        <ColorLegend color="green" text="Positive feedback" />
        <ColorLegend color="yellow" text="Neutral feedback" />
        <ColorLegend color="red" text="Negative feedback" />
        <ColorLegend color="blue" text="Count" />
      </div>
    </div>
  )
};

export default TrendLegend;