import * as React from 'react';
import axios from 'axios';
import { Col, ListGroupItem, FormGroup, ListGroup, Input, Row, Label, Button } from 'reactstrap';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Datepicker from 'react-datepicker'; // tslint:disable-line
import 'react-datepicker/dist/react-datepicker.css';
import * as querystring from 'querystring';

import Recipient from '../interfaces/Recipient';

import Loader from './Loader';
import StarResult from './StarResult';
import RecipientProps from './RecipientProps';
import RecipientsListState from './RecipientsListState';
import RecipientsListProps from './RecipientsListProps';

const dateOptions = { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric' };

interface InnerRecipientsListProps extends RouteComponentProps<any> {
  type: string;
}

class RecipientListGroupItem extends React.Component<RecipientProps, {}> {
  render() {
    const { recipient } = this.props;
    return <ListGroupItem className="recipient-list-group-item">
      {this.props.recipient.final_stage !== null &&
        <FormGroup className="mb-2">
          <strong style={{ color: this.props.recipient.final_stage.color || '#000' }}>
            {this.props.recipient.final_stage.version_name}
          </strong>
        </FormGroup>
      }
      <FormGroup row className="mb-2">
        <Col md="3">
          <b>Email address</b><br />
          {recipient.email}
        </Col>
        <Col md="3">
          <b>Reference ID</b><br />
          {recipient.reference_id}
        </Col>
      </FormGroup>
      <FormGroup row className="mb-2">
        {recipient.fields.map(f =>
          <Col md="3">
            <b>{f.display_name}</b><br />
            {f.value}
          </Col>)}
      </FormGroup>
      <FormGroup row className="mb-0">
        {recipient.results && recipient.results.map(result =>
          <Col md="3">
            <b>{result.name}</b><br />
            <StarResult result={result} />
          </Col>,
        )}
        {recipient.last_sent && !recipient.results &&
          <Col md="12">
            <b>Last sent</b><br />
            {new Date(recipient.last_sent)
              .toLocaleDateString('en-ZA', dateOptions)}
          </Col>
        }
        {!recipient.last_sent && recipient.scheduled_send &&
          <Col md="12">
            <b>Scheduled to send</b><br />
            {new Date(recipient.scheduled_send)
              .toLocaleDateString('en-ZA', dateOptions)}
          </Col>
        }
      </FormGroup>
    </ListGroupItem>;
  }
}

interface DateInputProps {
  onClick?: (evt: React.MouseEvent) => void;
  date: Date;
}

const DateInput = (props: DateInputProps) =>
  <Input
    onClick={props.onClick}
    value={props.date.toLocaleDateString()} />;

class RecipientsList extends React.Component<
  InnerRecipientsListProps,
  RecipientsListState
  >{
  listType: string;
  constructor(props: any) {
    super(props);
    const firstOfMonth = new Date();
    firstOfMonth.setHours(0, 0, 0);
    firstOfMonth.setDate(1);
    const lastOfMonth = new Date(firstOfMonth);
    lastOfMonth.setMonth(lastOfMonth.getMonth() + 1);
    this.state = {
      startDate: firstOfMonth,
      endDate: lastOfMonth,
      recipients: null,
      isLoading: true,
      errorMessage: null,
    };
  }
  startDateChanged = (startDate: Date) => {
    const { endDate } = this.state;
    const cb = () => this.reloadRecipients();
    if (endDate < startDate) {
      this.setState({ startDate, endDate: startDate }, cb);
    } else {
      this.setState({ startDate }, cb);
    }
  }
  endDateChanged = (endDate: Date) => {
    const { startDate } = this.state;
    const cb = () => this.reloadRecipients();
    if (endDate < startDate) {
      this.setState({ endDate, startDate: endDate }, cb);
    } else {
      this.setState({ endDate }, cb);
    }
  }
  render() {
    const { recipients, startDate, endDate } = this.state;
    return <div>
      <Row>
        <Col md="4">
          <FormGroup row>
            <Label md={3}>From</Label>
            <Col md={9}>
              <Datepicker
                selected={startDate}
                onChange={this.startDateChanged}
                customInput={<DateInput date={startDate} />}
              />
            </Col>
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup row>
            <Label md={3}>To</Label>
            <Col md={9}>
              <Datepicker
                selected={endDate}
                onChange={this.endDateChanged}
                customInput={<DateInput date={endDate} />}
              />
            </Col>
          </FormGroup>
        </Col>
        <Col md={4} />
        {recipients && recipients.length > 0 &&
          <Col md="12">
            <FormGroup>
              <a href={this.getDownloadUrl()} download>
                <Button>
                  Download XLSX
                </Button>
              </a>
            </FormGroup>
          </Col>
        }
      </Row>
      <hr className="mt-0" />
      <div className="position-relative">
        <Loader isLoading={this.state.isLoading} />
        {this.state.recipients !== null && (
          <React.Fragment>
            {(this.state.recipients as Recipient[]).length === 0 &&
              <p>No recipients were found.</p>
            }
            {(this.state.recipients as Recipient[]).length > 0 &&
              <ListGroup>
                {(this.state.recipients as Recipient[]).map(recipient =>
                  <RecipientListGroupItem
                    recipient={recipient}
                  />)}
              </ListGroup>}
          </React.Fragment>
        )}
      </div>
    </div>;
  }
  getDownloadUrl(): string {
    const params = { ...this.getFilter(), download: 1 };
    return `${this.getApiUrl()}?${querystring.stringify(params)}`;
  }
  getFilter(): any {
    const { startDate, endDate } = this.state;
    return {
      startDate: startDate.toDateString(),
      endDate: endDate.toDateString(),
    };
  }
  getApiUrl(): string {
    const { surveyId } = this.props.match.params;
    return `/api/surveys/${surveyId}/recipients/${this.props.type}`;
  }
  async reloadRecipients() {
    this.setState({ isLoading: true, recipients: null });
    const state = { isLoading: false };
    try {
      const url = this.getApiUrl();
      const { recipients } = (await axios.get(url, { params: this.getFilter() })).data;
      this.setState({ ...state, recipients });
    } catch (err) {
      const errorMessage =
        (err.response
          && err.response.data
          && err.response.data.message)
        || 'An internal error occurred';
      this.setState({ ...state, errorMessage });
    }
  }
  async componentDidMount() {
    await this.reloadRecipients();
  }
}
export default withRouter(RecipientsList);
