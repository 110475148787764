import * as React from 'react';
import Recipient from '../interfaces/Recipient';

import axios from 'axios';
import { Link, Switch, Route, withRouter, RouteComponentProps } from 'react-router-dom';
import {
  Table, ButtonGroup, Button, Input, Dropdown, DropdownItem,
  DropdownToggle, DropdownMenu,
} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faThumbsUp, faTrash } from '@fortawesome/free-solid-svg-icons';

import Loader from './Loader';
import SurveyField from '../interfaces/SurveyField';
import RecipientField from '../interfaces/RecipientField';
import RecipientProps from './RecipientProps';
import RecipientsListState from './RecipientsListState';
import RecipientsListProps from './RecipientsListProps';

interface SubmittedRecipientsListState extends RecipientsListState {
  recipients: Recipient[];
  fields: SurveyField[];
  newRecipientRow: JSX.Element;
}

interface SubmittedRecipientRowProps extends RecipientProps {
  allFields: SurveyField[];
  removeRow: () => void;
  save: (r: Recipient, success: () => void, error: (err: Error) => void) => void;
}

interface RecipientRowState {
  modified: boolean;
  isLoading: boolean;
  errorMessage: string;
  recipient: Recipient;
}

class SubmittedRecipientRow extends React.Component<
  SubmittedRecipientRowProps,
  RecipientRowState
  > {
  constructor(props: SubmittedRecipientRowProps) {
    super(props);
    const recipient = this.props.recipient || {
      email: '',
      reference_id: '',
      fields: [] as RecipientField[],
      id: null as number,
    };
    const fields = this.props.allFields.map((surveyField: SurveyField) => {
      let theRecipientField;
      for (const recipientField of recipient.fields) {
        if (recipientField.name === surveyField.name) {
          theRecipientField = recipientField;
          break;
        }
      }
      const { name } = surveyField;
      const value = theRecipientField && theRecipientField.value || '';
      return { name, value } as RecipientField;
    });

    const { id, email, reference_id } = recipient;
    const stateRecipient: Recipient = { email, reference_id, fields, id, results: [] };

    this.state = {
      recipient: stateRecipient,
      isLoading: false,
      modified: false,
      errorMessage: null,
    };
  }
  setEmail = (email: string) => {
    const recipient = { ...this.state.recipient, email };
    this.setState({ recipient, modified: true });
  }
  setReferenceId = (reference_id: string) => {
    const recipient = { ...this.state.recipient, reference_id };
    this.setState({ recipient, modified: true });
  }
  setField = (name: string, value: string) => {
    const recipient = { ...this.state.recipient };
    const fields = recipient.fields.map(field => ({
      name: field.name,
      value: (field.name === name ? value : field.value),
    }));
    recipient.fields = fields;
    this.setState({ recipient, modified: true });
  }
  setModified = () => {
    this.setState({ modified: true });
  }
  save = () => {
    this.setState({
      isLoading: true,
      errorMessage: null,
    });
    this.props.save(
      this.state.recipient,
      () => this.setState({
        isLoading: false,
        modified: false,
      }),
      (err: Error) => this.setState({
        isLoading: false,
        errorMessage: err.message,
      }),
    );
  }
  approve = () => {
    if (this.state.recipient.id === null || this.state.modified || this.state.isLoading) {
      return;
    }
    this.setState({ isLoading: true });
    axios.post(`/api/recipients/${this.props.recipient.id}/approve`).then((response) => {
      this.props.removeRow();
    }).catch((err) => {
      console.error(err.response || err);
    }).then(() => {
      this.setState({ isLoading: false });
    });
  }
  delete = () => {
    if (this.state.recipient.id === null || this.state.modified || this.state.isLoading) {
      return;
    }
    this.setState({ isLoading: true });
    axios.delete(`/api/recipients/${this.props.recipient.id}`).then((response) => {
      this.props.removeRow();
    }).catch((err) => {
      console.error(err.response || err);
    }).then(() => {
      this.setState({ isLoading: false });
    });
  }
  render() {
    const rowStyle = this.state.recipient.id === null ? { background: '#b9fba9' } : {};
    return <React.Fragment>
      <tr style={rowStyle}>
        <td>
          <Input
            type="text"
            defaultValue={this.state.recipient.email}
            disabled={this.state.isLoading}
            onChange={e => this.setEmail(e.target.value)}
          />
        </td>
        <td>
          <Input
            type="text"
            defaultValue={this.state.recipient.reference_id}
            disabled={this.state.isLoading}
            onChange={e => this.setReferenceId(e.target.value)}
          />
        </td>
        {this.state.recipient.fields.map(field =>
          <td>
            <Input
              type="text"
              defaultValue={field.value}
              disabled={this.state.isLoading}
              onChange={e => this.setField(field.name, e.target.value)}
            />
          </td>,
        )}
        <td >
          <Button
            color="primary"
            size="sm"
            disabled={!this.state.modified || this.state.isLoading}
            onClick={this.save}>
            <FontAwesomeIcon icon={faSave} />
          </Button>
          {this.props.recipient !== null &&
            <React.Fragment>
              <Button
                className="ml-2"
                color="danger"
                size="sm"
                disabled={this.state.isLoading}
                onClick={this.delete}>
                <FontAwesomeIcon icon={faTrash} />
              </Button>

              <Button
                className="ml-2"
                color="success"
                size="sm"
                disabled={this.state.modified || this.state.isLoading}
                onClick={this.approve}>
                <FontAwesomeIcon icon={faThumbsUp} />
              </Button>
            </React.Fragment>
          }
        </td>
      </tr>
      {this.state.errorMessage && <tr style={rowStyle}>
        <td
          colSpan={this.props.allFields.length + 3}>
          <span className="text-danger">
            {this.state.errorMessage}
          </span>
        </td>
      </tr>
      }
    </React.Fragment>;
  }
}

let z = 0;

const SubmittedRecipients = class
  extends React.Component<RecipientsListProps, SubmittedRecipientsListState>{
  constructor(props: any) {
    super(props);
    this.state = {
      recipients: null,
      isLoading: true,
      fields: null,
      errorMessage: null,
      newRecipientRow: null,
      startDate: new Date(),
      endDate: new Date(),
    };
  }
  saveNew = (
    recipient: Recipient,
    success: () => void,
    error: (err: Error) => void) => {
    const { surveyId } = this.props.match.params;
    axios.post(
      `/api/surveys/${surveyId}/recipients`,
      recipient,
    ).then((response) => {
      this.reloadRecipients();
    }).catch((err) => {
      const e = new Error(
        err.response
        && err.response.data
        && err.response.data.message
        || 'An internal error occurred.',
      );
      return error(e);
    });
  }
  saveExisting = (
    recipient: Recipient,
    success: () => void,
    error: (err: Error) => void) => {
    axios.put(
      `/api/recipients/${recipient.id}`,
      recipient,
    ).then((response) => {
      return success();
    }).catch((err) => {
      const e = new Error(
        err.response
        && err.response.data
        && err.response.data.message
        || 'An internal error occurred.',
      );
      return error(e);
    });
  }

  createNewRecipient(): Recipient {
    return {
      id: null,
      email: '',
      reference_id: '',
      fields: [],
      results: [],
    };
  }
  getNext() {
    return z += 1;
  }
  render() {
    return <div>
      <Loader isLoading={this.state.isLoading} />
      {this.state.recipients !== null && (
        <React.Fragment>
          {this.state.fields !== null && <div>
            <Table size="sm">
              <thead>
                <tr>
                  <th>Email</th>
                  <th>Ref. ID</th>
                  {this.state.fields.map(field =>
                    <th key={field.name}>{field.display_name}</th>,
                  )}
                  <th />
                </tr>
              </thead>
              <tbody>
                <SubmittedRecipientRow
                  key={this.getNext()}
                  allFields={this.state.fields}
                  recipient={null}
                  save={this.saveNew}
                  removeRow={null}
                />
                {this.state.recipients.map((recipient) => {
                  const removeRow = () => {
                    const recipients = [...this.state.recipients];
                    const index = recipients.indexOf(recipient);
                    if (index > -1) {
                      recipients.splice(index, 1);
                      this.setState({ recipients });
                    }
                  };
                  return <SubmittedRecipientRow
                    allFields={this.state.fields}
                    key={recipient.id}
                    recipient={recipient}
                    removeRow={removeRow}
                    save={this.saveExisting}
                  />;
                })}
              </tbody>
            </Table>
            {this.state.recipients.length === 0 && !this.state.isLoading &&
              <p className="text-danger">No recipients were found.</p>
            }
          </div>}
        </React.Fragment>
      )}
    </div>;
  }
  reloadRecipients() {
    this.setState({ isLoading: true });
    const { surveyId } = this.props.match.params;
    axios.get(
      `/api/surveys/${surveyId}/recipients/submitted`,
    ).then((recipientResponse) => {
      const { recipients } = recipientResponse.data;
      this.setState({
        recipients,
        isLoading: false,
      });
    });
  }
  componentDidMount() {
    this.setState({ isLoading: true });
    const state = { isLoading: false };
    const { surveyId } = this.props.match.params;
    const br = `/api/surveys/${surveyId}`;
    axios.all([
      axios.get(`${br}/recipients/submitted`),
      axios.get(`${br}/fields`),
    ]).then(axios.spread((recipientResponse: any, fieldResponse: any) => {
      const { recipients } = recipientResponse.data;
      const { fields } = fieldResponse.data;
      this.setState({ ...state, fields, recipients });
    })).catch((err) => {
      const errorMessage =
        (err.response
          && err.response.data
          && err.response.data.message)
        || 'An internal error occurred';
      this.setState({ ...state, errorMessage });
    });
  }
};

export default withRouter(SubmittedRecipients);
