import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
  FormGroup,
  Label,
  Row,
  Col,
  Input,
  Button,
} from 'reactstrap';
import Client from '../interfaces/Client';
import DeleteClientModal from './DeleteClientModal';
import Loader from './Loader';
import axios from 'axios';

interface ClientSettingsComponentProps extends RouteComponentProps<any> {
  client: Client;
  onClientDeleted?: () => void;
  onClientUpdate?: () => void;
}

interface ClientSettingsComponentState {
  isLoading?: boolean;
  deleteModalIsOpen?: boolean;
  errorMessage?: string;
}

export default class ClientSettingsComponent extends
  React.Component<ClientSettingsComponentProps, ClientSettingsComponentState>
{
  nameRef = React.createRef<HTMLInputElement>();
  constructor(props: any) {
    super(props);
    this.state = {};
  }
  save = async () => {
    const clientId = this.props.client.id;
    this.setState({ isLoading: true, errorMessage: null });
    try {
      const data = {
        name: this.nameRef.current.value,
      };
      await axios.put(`/api/clients/${clientId}`, data);
      this.setState({ isLoading: false });
    } catch (err) {
      const errorMessage = 'fff';
      this.setState({ errorMessage, isLoading: false });
    }
  }
  clientWasDeleted = async () => {
    this.props.onClientDeleted();
  }
  render() {
    const { client } = this.props;
    const { isLoading, deleteModalIsOpen } = this.state;
    const disabled = isLoading || !client.can_delete;
    return (
      <div>
        <Loader isLoading={isLoading} />
        <DeleteClientModal
          client={client}
          isOpen={deleteModalIsOpen}
          wasClosed={() => this.setState({ deleteModalIsOpen: false })}
          wasSuccessful={this.clientWasDeleted} />
        {!client.can_delete && <p className="text-danger">This client is locked.</p>}
        <FormGroup row>
          <Label md={3}>Name</Label>
          <Col md={9}>
            <Input
              type="text"
              disabled={disabled}
              innerRef={this.nameRef}
              defaultValue={client.name}
            />
          </Col>
        </FormGroup>
        <FormGroup>
          <Button
            color="success"
            disabled={disabled}
            onClick={this.save}>
            Save changes
          </Button>
        </FormGroup>
        <hr />
        <FormGroup>
          {client.can_delete &&
            <Button color="danger"
              disabled={disabled}
              onClick={() => this.setState({ deleteModalIsOpen: true })}>
              Delete this client
            </Button>
          }
        </FormGroup>
      </div>
    );
  }
}
