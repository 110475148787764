import * as React from 'react';
import axios from 'axios';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import Client from '../interfaces/Client';
import Loader from './Loader';

interface DeleteClientModalProps {
  isOpen: boolean;
  wasClosed: () => void;
  wasSuccessful: () => void;
  className?: string;
  client: Client;
}

interface DeleteClientModalState {
  isLoading?: boolean;
  error?: string;
}

export default class DeleteClientModal extends React.Component
  <
  DeleteClientModalProps,
  DeleteClientModalState
  >
{
  constructor(props: DeleteClientModalProps) {
    super(props);
    this.state = { isLoading: false };
  }
  okClicked = async (e: React.MouseEvent) => {
    const { client } = this.props;
    const url = `/api/clients/${client.id}`;
    this.setState({ isLoading: true });
    try {
      await axios.delete(url);
      this.props.wasClosed();
      this.props.wasSuccessful();
    } catch (err) {
      console.error(err);
    }
    this.setState({ isLoading: false });
  }
  render() {
    const { isOpen, client, wasClosed } = this.props;
    const { isLoading } = this.state;
    const okEnabled = !isLoading;
    return <div>
      <Modal isOpen={isOpen} toggle={wasClosed} className={this.props.className}>
        <ModalHeader toggle={wasClosed}>
          Delete {client.name}
        </ModalHeader>
        <ModalBody>
          <p className="mb-0">
            Deleting this client will delete all of its data, along with any associated surveys.
            <br />
            <br />
            This cannot be undone. Continue?
            </p>
          <Loader isLoading={isLoading} />
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={!okEnabled}
            color="danger"
            onClick={this.okClicked}>
            Delete
          </Button>{' '}
          <Button color="secondary" onClick={wasClosed}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>;
  }
}
