import * as React from 'react';
import axios from 'axios';
import { Route, withRouter, RouteComponentProps, matchPath } from 'react-router-dom';
import {
  FormGroup,
  Button,
  Col,
} from 'reactstrap';

import Client from '../interfaces/Client';

import NewClientModal from './NewClientModal';
import ClientView from './ClientView';
import ItemDropDown from './ItemDropDown';
import Loader from './Loader';

interface ClientsListState {
  isLoading: boolean;
  clients: Client[];
  selectedClient: Client;
  dropdownOpen: boolean;
  newClientModalShowing: boolean;
  errorMessage?: string;
}

interface ClientRouteComponentState {
  isLoading?: boolean;
  client?: Client;
  error?: string;
}

interface ClientData {
  client: Client;
}

interface ClientRouteParams {
  clientId?: string;
}

interface ClientRouteComponentProps extends RouteComponentProps<ClientRouteParams> {
  onClientDeleted: () => void;
}

const ClientRouteComponent = withRouter(
  class extends React.Component<ClientRouteComponentProps, ClientRouteComponentState>
  {
    constructor(props: any) {
      super(props);
      this.state = {};
    }
    async componentDidMount() {
      await this.refreshClient();
    }
    refreshClient = async () => {
      const { clientId } = this.props.match.params;
      this.setState({ isLoading: true });
      try {
        const { client } =
          (await axios.get<ClientData>(`/api/clients/${clientId}`)).data;
        this.setState({ client, isLoading: false });
      } catch (err) {
        const error = err.response
          && err.response.data
          && err.response.data.message
          || 'An error occurred';
        console.error(err);
        this.setState({ error, isLoading: false });
      }
    }
    render() {
      const { client, isLoading } = this.state;
      return <div className="position-relative">
        <Loader isLoading={isLoading} />
        {client && <ClientView client={client} onClientDeleted={this.props.onClientDeleted} />}
      </div>;
    }
  });

const ClientsList = withRouter(class extends React.Component<
  RouteComponentProps<any>, ClientsListState
  > {
  constructor(props: any) {
    super(props);
    this.state = {
      isLoading: true,
      clients: [],
      selectedClient: null,
      dropdownOpen: false,
      newClientModalShowing: false,
    };
  }
  clientWasCreated = async () => {
    this.setState({ newClientModalShowing: false });
    await this.refreshClients();
  }
  toggle = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  }
  onClientDeleted = async (client: Client) => {
    this.setClient(null);
    this.refreshClients();
  }
  setClient = (selectedClient: Client) => {
    this.setState({ selectedClient });
    const tail = selectedClient && `${selectedClient.id}/surveys` || '';
    this.props.history.push(`${this.props.match.url}${tail}`);
  }
  render() {
    const { isLoading, selectedClient, clients, newClientModalShowing } = this.state;
    return <div className="position-relative">
      <Loader isLoading={isLoading} />
      {!isLoading &&
        <div>
          <NewClientModal
            isOpen={newClientModalShowing}
            wasClosed={() => this.setState({ newClientModalShowing: false })}
            wasSuccessful={this.clientWasCreated}
          />
          <FormGroup row>
            <Col md="6">
              {clients && clients.length > 0 &&
                <ItemDropDown<Client>
                  defaultLabel="Select a client"
                  items={clients}
                  getName={i => i.name}
                  selectedItem={selectedClient}
                  onItemChanged={this.setClient} />
              }
            </Col>
            <Col md="6">
              <Button color="primary"
                onClick={() => this.setState({ newClientModalShowing: true })}>
                New client</Button>
              {' '}
            </Col>
          </FormGroup>
          <Route
            path={this.getClientRoutePath()}
            render={props =>
              <ClientRouteComponent
                {...props}
                onClientDeleted={this.onClientDeleted}
              />
            }
          />
        </div>
      }
    </div>;
  }
  getClientRoutePath(): string {
    return `${this.props.match.url}:clientId`;
  }
  refreshClients = async () => {
    try {
      const { history } = this.props;
      const { clients }
        = (await axios.get<{ clients: Client[] }>('/api/clients')).data;
      const state = { clients, isLoading: false } as ClientsListState;
      const path = this.getClientRoutePath();
      const m = matchPath<{ clientId: string }>(history.location.pathname, { path });
      if (m && m.params.clientId) {
        state.selectedClient = clients.find(s => s.id === parseInt(m.params.clientId, 10));
      }
      this.setState(state);
    } catch (err) {
      const errorMessage = err.response
        && err.response.data
        && err.response.data.message
        || 'An error occurred';
      console.error(err.response || err);
      this.setState({ errorMessage, isLoading: false });
    }
  }
  async componentDidMount() {
    await this.refreshClients();
  }
});

export default ClientsList;
