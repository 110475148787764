import * as React from 'react';
import Bar from './Bar';
import BarGraphFormat from './BarGraphFormat';
import BarGraphDisplay from './BarGraphDisplay/BarGraphDisplay';
import BarGraphData from './BarGraphData';
import Trend from './Trend';
import ReportControl from './ReportControl';

function getMax(preciseMax: number, interval: number) {
  const rem = preciseMax % interval;
  return ((preciseMax - rem) / interval + 1) * interval;
}

function getSteps(actualMin: number, max: number, interval: number) {
  const flip = -(actualMin - max);
  const rem = flip % interval;
  return ((flip - rem) / interval + (rem < interval / 2 ? 1 : 2));
}

function getMin(max: number, steps: number, interval: number) {
  return max - steps * interval;
}

function getInterval(delta: number, minInterval: number, desiredSteps: number) {
  const preciseInterval = delta / desiredSteps;
  const rem = preciseInterval % minInterval;
  return Math.max(preciseInterval - rem, 0) + minInterval;
}

export default class BarGraph extends ReportControl {
  min: number;
  max: number;
  steps: number;
  bars: Bar[];
  format: BarGraphFormat;
  title: string;
  trend: Trend;
  decimalPlaces: number;
  width?: 'full';
  constructor(
    data: BarGraphData,
    allData: BarGraphData[],
  ) {
    super();
    const { format, group, title, decimalPlaces, trend, bars, max, width } = data;
    const minimumInterval = 5;
    const allValues = allData
      .filter(bd => !group && bd === data || bd.group && bd.group === group)
      .map(ad => ad.bars
        .map(b => b.segments.reduce((t, c) => t + c.value, 0)))
      .reduce((a, c) => [...a, ...c], []);
    const actualMin = Math.min(...allValues);
    const actualMax = Math.max(...allValues);
    // this.max = Math.max(...allValues);
    const interval = getInterval((max || actualMax) - actualMin, minimumInterval, 5);
    this.max = max || getMax(actualMax, interval);
    this.steps = getSteps(actualMin, this.max, interval);
    this.min = getMin(this.max, this.steps, interval);
    if (this.min < 0) {
      this.steps -= 1;
      this.min = getMin(this.max, this.steps, interval);
    }
    this.format = format;
    this.decimalPlaces = decimalPlaces;
    this.title = title;
    this.trend = trend;
    this.bars = bars;
    this.width = width;
  }
  render(key: string | number) {
    return <BarGraphDisplay key={key} barGraph={this} height={500} />;
  }
}
