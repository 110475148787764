import * as React from 'react';

import axios from 'axios';
import { Route, withRouter, RouteComponentProps, matchPath } from 'react-router-dom';
import {
  FormGroup,
  Col,
} from 'reactstrap';

import Survey from '../interfaces/Survey';

import ItemDropDown from './ItemDropDown';
import SurveyView from './SurveyView';
import Loader from './Loader';

interface SurveyRouteComponentState {
  isLoading?: boolean;
  survey?: Survey;
  error?: string;
}

interface SurveyData {
  survey: Survey;
}

interface SurveyRouteParams {
  surveyId?: string;
}

interface SurveyRouteComponentProps extends RouteComponentProps<SurveyRouteParams> {
  onSurveyDeleted: () => void;
}

const SurveyRouteComponent = withRouter(
  class extends React.Component<SurveyRouteComponentProps, SurveyRouteComponentState>
  {
    constructor(props: SurveyRouteComponentProps) {
      super(props);
      this.state = {};
    }
    async componentDidMount() {
      await this.refreshSurvey();
    }
    async componentDidUpdate(oldProps: SurveysListProps) {
      if (oldProps.match.params.surveyId !== this.props.match.params.surveyId) {
        await this.refreshSurvey();
      }
    }
    refreshSurvey = async () => {
      const { surveyId } = this.props.match.params;
      this.setState({ isLoading: true });
      try {
        const { survey } =
          (await axios.get<SurveyData>(`/api/surveys/${surveyId}`)).data;
        this.setState({ survey, isLoading: false });
      } catch (err) {
        const error = err.response
          && err.response.data
          && err.response.data.message
          || 'An error occurred';
        console.error(err);
        this.setState({ error, isLoading: false });
      }
    }
    render() {
      const { survey, isLoading } = this.state;
      return <div className="position-relative">
        <Loader isLoading={isLoading} />
        {survey && <SurveyView
          survey={survey}
          onSurveyUpdated={this.refreshSurvey}
          onSurveyDeleted={this.props.onSurveyDeleted}
        />}
      </div>;
    }
  });

interface SurveysListState {
  isLoading: boolean;
  surveys: Survey[];
  selectedSurvey: Survey;
  dropdownOpen: boolean;
  error: string;
  cloneSurveyModalIsOpen: boolean;
}

interface SurveysListProps extends RouteComponentProps<any> {
}

const SurveysList = withRouter(class extends React.Component<SurveysListProps, SurveysListState> {
  constructor(props: any) {
    super(props);
    this.state = {
      isLoading: true,
      surveys: [],
      selectedSurvey: null,
      dropdownOpen: false,
      error: null,
      cloneSurveyModalIsOpen: false,
    };
  }
  setSurvey = (selectedSurvey: Survey) => {
    this.setState({ selectedSurvey });
    const tail = '/recipients/awaiting-approval';
    this.props.history.push(`${this.props.match.url}/${selectedSurvey.id}${tail}`);
  }
  async componentDidUpdate(oldProps: SurveysListProps) {
    if (oldProps.match.params.clientId !== this.props.match.params.clientId) {
      this.setState({ selectedSurvey: null });
      await this.loadSurveys();
    }
  }
  getSurveyRoutePath(): string {
    return `${this.props.match.url}/:surveyId`;
  }
  render() {
    const { isLoading, selectedSurvey, surveys } = this.state;
    return <div>
      <Loader isLoading={isLoading} />
      {!isLoading &&
        <div>
          <FormGroup row>
            <Col md="6">
              {surveys && surveys.length > 0 &&
                <ItemDropDown<Survey>
                  defaultLabel="Select a survey"
                  items={surveys}
                  selectedItem={selectedSurvey}
                  getName={i => i.name}
                  onItemChanged={this.setSurvey} />
              }
            </Col>
          </FormGroup>
          <Route
            path={this.getSurveyRoutePath()}
            render={
              props =>
                <SurveyRouteComponent
                  {...props}
                  onSurveyDeleted={this.loadSurveys} />
            }
          />
        </div>
      }
    </div>;
  }
  loadSurveys = async () => {
    this.setState({ isLoading: true, error: null });
    const { match, history } = this.props;
    const { clientId } = match.params;
    try {
      const { surveys }
        = (await axios.get<{ surveys: Survey[] }>(`/api/clients/${clientId}/surveys`)).data;
      const state = { surveys, isLoading: false } as SurveysListState;
      const path = this.getSurveyRoutePath();
      const m = matchPath<{ surveyId: string }>(history.location.pathname, { path });
      if (m && m.params.surveyId) {
        state.selectedSurvey = surveys.find(s => s.id === parseInt(m.params.surveyId, 10));
      }
      this.setState(state);
    } catch (err) {
      const error = err.response
        && err.response.data
        && err.response.data.message
        || 'An error occurred';
      console.error(err);
      this.setState({ error });
    }
  }
  async componentDidMount() {
    await this.loadSurveys();
  }
});

export default SurveysList;
