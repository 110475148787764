import * as React from 'react';
import axios from 'axios';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';
import Client from '../interfaces/Client';

interface NewClientModalProps {
  isOpen: boolean;
  wasClosed: () => void;
  className?: string;
  wasSuccessful: () => void;
}

interface NewClientModalState {
  isLoading?: boolean;
}

export default class NewClientModal extends React.Component
  <
  NewClientModalProps,
  NewClientModalState
  >
{
  constructor(props: NewClientModalProps) {
    super(props);
    this.state = { isLoading: false };
  }
  private clientNameRef = React.createRef<HTMLInputElement>();
  okClicked = async (e: React.MouseEvent) => {
    this.setState({ isLoading: true });
    try {
      const name = this.clientNameRef.current.value;
      const response = await axios.post('/api/clients', { name });
      this.props.wasSuccessful();
    } catch (err) {
      console.error(err);
    }
  }
  componentDidUpdate(oldProps: NewClientModalProps) {
    if (this.props.isOpen !== oldProps.isOpen) {
      this.setState({ isLoading: false });
    }
  }
  wasClosed = () => {
    this.props.wasClosed();
  }
  render() {
    const { isOpen } = this.props;
    const wasClosed = this.wasClosed;
    const { isLoading } = this.state;
    const cr = this.clientNameRef.current;
    const okEnabled = !isLoading;
    return <div>
      <Modal isOpen={isOpen} toggle={wasClosed} className={this.props.className}>
        <ModalHeader toggle={wasClosed}>Create a new client</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label>
              Enter the name of the new client
            </Label>
            <Input innerRef={this.clientNameRef} placeholder="New client" />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={!okEnabled}
            color="primary"
            onClick={this.okClicked}>
            Create
          </Button>{' '}
          <Button color="secondary" onClick={wasClosed}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>;
  }
}
