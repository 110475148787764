import * as React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as faStarEmpty } from '@fortawesome/free-regular-svg-icons';

import RecipientResult from '../interfaces/RecipientResult';

interface StarResultProps {
  result: RecipientResult;
}

export default class StarResult extends React.Component<StarResultProps, {}> {
  render() {
    return <div>
      {[1, 2, 3, 4, 5].map((i) => {
        const filled = this.props.result.result >= i;
        return <span>
          {filled && <FontAwesomeIcon icon={faStar} />}
          {!filled && <FontAwesomeIcon icon={faStarEmpty} />}
        </span>;
      })
      }
    </div>;
  }
}
