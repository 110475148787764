import * as React from 'react';
import classnames from 'classnames';
import { NavItem, NavLink } from 'reactstrap';
import { Link, Route, withRouter, RouteComponentProps } from 'react-router-dom';

interface RNavProps extends RouteComponentProps<any> {
  path: string;
}

const RNavInner = withRouter(class extends React.Component<RNavProps> {
  render() {
    return <NavItem>
      <NavLink
        className={classnames({ active: !!this.props.match })}
        tag={Link}
        to={this.props.path}>
        {this.props.children}
      </NavLink>
    </NavItem >;
  }
});

const RNav = withRouter(class extends React.Component<RNavProps> {
  render() {
    return <Route path={this.props.path}>
      <RNavInner path={this.props.path}>
        {this.props.children}
      </RNavInner>
    </Route>;
  }
});

export default RNav;
