import * as React from 'react';
import { Col, Row, FormGroup, Input, Label, Button, Form } from 'reactstrap';
import axios from 'axios';
import AuthData from '../interfaces/AuthData';
import { withRouter, RouteComponentProps } from 'react-router';

interface LoginFormState {
  isLoading: boolean;
  errorMessage: string;
}

class LoginForm extends React.Component<RouteComponentProps<{}>, LoginFormState> {
  private usernameRef = React.createRef<HTMLInputElement>();
  private passwordRef = React.createRef<HTMLInputElement>();

  constructor(props: any) {
    super(props);
    this.state = {
      isLoading: false,
      errorMessage: null,
    };
  }
  submit = async (e: React.FormEvent) => {
    e.preventDefault();
    const authPayload = {
      username: this.usernameRef.current.value,
      password: this.passwordRef.current.value,
    };
    this.setState({ isLoading: true, errorMessage: null });
    try {
      const authData = (await axios.post<AuthData>('/api/login', authPayload)).data;
      this.setState({ isLoading: false });
      this.props.history.push('/');
    } catch (err) {
      const message = err.response
        && err.response.data
        && err.response.data.message
        || 'An internal error occurred.';
      this.setState({ isLoading: false, errorMessage: message });
    }
  }
  render() {
    const { isLoading } = this.state;
    return <Row>
      <Col md={{ size: 4, offset: 4 }}>
        <h3 className="mt-4 mb-4">Client portal login</h3>
        <Form onSubmit={this.submit}>
          <FormGroup>
            <Label>Username</Label>
            <Input
              disabled={isLoading}
              type="text"
              placeholder="user@company.com"
              innerRef={this.usernameRef}
            />
          </FormGroup>
          <FormGroup>
            <Label>Password</Label>
            <Input
              disabled={isLoading}
              isLoading={isLoading}
              type="password"
              placeholder=""
              innerRef={this.passwordRef} />
          </FormGroup>
          <FormGroup className="mt-3">
            <Button
              color="primary"
              type="submit"
              disabled={isLoading}>
              Submit
          </Button>
          </FormGroup>
          <FormGroup>
            {this.state.errorMessage &&
              <Label className="text-danger">{this.state.errorMessage}</Label>
            }
          </FormGroup>
        </Form>
      </Col>
    </Row>;
  }
}

export default withRouter(LoginForm);
