import * as React from 'react';
import { Switch, Route } from 'react-router-dom';

import LoginForm from './LoginForm';
import { Portal } from './Portal';

export default () => (
  <div className="container">
    <Switch>
      <Route path="/login" exact={true} component={LoginForm} />
      <Route path="/" component={Portal} />
    </Switch>
  </div >
);
