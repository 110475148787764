import * as React from 'react';
import classnames from 'classnames';
import './ReportLogo.scss';

const ReportLogo: React.FC<{ url: string, className?: string, footer?: boolean }> = ({ url, className, footer }) => (
  <div
    className={classnames('report-logo', className, { footer })}
  >
    <img src={url} />
  </div>
);

export default ReportLogo;