import * as React from 'react';
import axios from 'axios';
import Survey from '../../interfaces/Survey';
import Loader from '../Loader';
import PopiData from '../../interfaces/PopiData';
import { ListGroup } from 'reactstrap';
import PopiControl from './PopiControl/PopiControl';

interface MonthYearDate {
  month: number;
  year: number;
}

function getEarliestMonthDate(survey: Survey, popiData: PopiData[]): MonthYearDate {
  const surveyTimestamp = new Date(survey.created);
  return [
    ...(!isNaN(surveyTimestamp.getTime())
      ? [{
        year: surveyTimestamp.getFullYear(),
        month: surveyTimestamp.getMonth(),
      }]
      : []
    ),
    ...popiData,
  ].reduce(
    (earliest, { year, month }) =>
      earliest.year === null
        || year < earliest.year
        || year === earliest.year && month < earliest.month
        ? { year, month }
        : { ...earliest },
    { year: null, month: null },
  );
}

function getCompletePopiData(survey: Survey, popiData: PopiData[]) {
  const earliestDate = getEarliestMonthDate(survey, popiData);
  const now = new Date();
  const maxDate = { month: now.getMonth() + 1, year: now.getFullYear() };
  const completeData = [];
  for (let { year } = earliestDate; year <= maxDate.year; year += 1) {
    for (
      let month = (year === earliestDate.year ? earliestDate.month : 1);
      month <= maxDate.month && year === maxDate.year || month < 13 && year < maxDate.year;
      month += 1
    ) {
      completeData.push(
        popiData.find(pd => pd.year === year && pd.month === month)
        || { year, month, numYes: 0, numNo: 0 },
      );
    }
  }
  return completeData.reverse();
}

const SurveyPopiView: React.FC<{ survey: Survey }> = ({ survey }) => {
  const [popiData, setPopiData] = React.useState<PopiData[] | null>(null);
  const [isLoading, setIsLoading] = React.useState(true);

  async function refresh() {
    setIsLoading(true);
    const { popiData } =
      (await axios.get<{ popiData: PopiData[] }>(`/api/surveys/${survey.id}/popi-data`)).data;
    setPopiData(getCompletePopiData(survey, popiData));
    setIsLoading(false);
  }

  React.useEffect(() => { refresh(); }, []);
  return (
    <div>
      <Loader isLoading={isLoading} />
      {!isLoading && popiData !== null &&
        <ListGroup flush>
          {popiData.map((pd, i) => <PopiControl popiData={pd} survey={survey} key={i} />)}
        </ListGroup>
      }
    </div>
  );
};

export default SurveyPopiView;
