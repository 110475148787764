import * as React from 'react';
import AuthData from '../interfaces/AuthData';
import axios from 'axios';
import { RouteComponentProps } from 'react-router';
import { Switch, Route, withRouter } from 'react-router-dom';

import { Row, Col } from 'reactstrap';

import PortalNav from './PortalNav';
import ClientsList from './ClientsList';
import ManageAssetsView from './ManageAssetsView';
import ManageWebModuleParametersView from './ManageWebModuleParametersView';

interface PortalState {
  sessionData: AuthData;
}

const Portal = withRouter(class extends React.Component<RouteComponentProps<{}>, PortalState> {
  constructor(props: RouteComponentProps<{}>) {
    super(props);
    this.state = { sessionData: null };
  }
  render() {
    return (
      <div>
        {this.state.sessionData !== null &&
          <div>
            <PortalNav sessionData={this.state.sessionData} />
            <Row className="mt-3">
              <Col md="12">
                <Switch>
                  <Route path="/" component={ClientsList} />
                </Switch>
              </Col>
            </Row>
          </div>
        }
      </div>
    );
  }
  async componentDidMount() {
    try {
      const sessionData = (await axios.get<AuthData>('/api/session')).data;
      this.setState({ sessionData });
    } catch (err) {
      console.error(err.response);
      this.props.history.push('/login');
    }
  }
});

export { Portal };
