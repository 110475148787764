import * as React from 'react';
import axios from 'axios';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import Survey from '../interfaces/Survey';
import Loader from './Loader';

interface DeleteSurveyModalProps {
  isOpen: boolean;
  wasClosed: () => void;
  wasSuccessful: () => void;
  className?: string;
  survey: Survey;
}

interface DeleteSurveyModalState {
  isLoading?: boolean;
  error?: string;
}

export default class DeleteSurveyModal extends React.Component
  <
  DeleteSurveyModalProps,
  DeleteSurveyModalState
  >
{
  constructor(props: DeleteSurveyModalProps) {
    super(props);
    this.state = { isLoading: false };
  }
  okClicked = async (e: React.MouseEvent) => {
    const { survey } = this.props;
    const url = `/api/surveys/${survey.id}`;
    this.setState({ isLoading: true });
    try {
      await axios.delete(url);
      this.props.wasClosed();
      this.props.wasSuccessful();
    } catch (err) {
      console.error(err);
    }
    this.setState({ isLoading: false });
  }
  render() {
    const { isOpen, survey, wasClosed } = this.props;
    const { isLoading } = this.state;
    const okEnabled = !isLoading;
    return <div>
      <Modal isOpen={isOpen} toggle={wasClosed} className={this.props.className}>
        <ModalHeader toggle={wasClosed}>
          Delete {survey.name}
        </ModalHeader>
        <ModalBody>
          <p className="mb-0">
            Deleting this survey will delete all of it's historical data, assets, and parameters.
            <br />
            <br />
            This cannot be undone. Continue?
            </p>
          <Loader isLoading={isLoading} />
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={!okEnabled}
            color="danger"
            onClick={this.okClicked}>
            Delete
          </Button>{' '}
          <Button color="secondary" onClick={wasClosed}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>;
  }
}
