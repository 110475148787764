import * as React from 'react';
import axios from 'axios';
import PopiData from '../../../interfaces/PopiData';
import { Row, Col, Input, Label, ListGroupItem } from 'reactstrap';
import Button from 'reactstrap/lib/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import Survey from '../../../interfaces/Survey';
import Loader from '../../Loader';

const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const PopiControl: React.FC<{ popiData: PopiData, survey: Survey }> = ({ popiData, survey }) => {
  const { numNo, numYes, month, year } = popiData;
  const [id, setId] = React.useState(popiData.id);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isDirty, setIsDirty] = React.useState(false);
  const yesRef = React.createRef<HTMLInputElement>();
  const noRef = React.createRef<HTMLInputElement>();

  async function save() {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    const data = {
      month,
      year,
      numYes: yesRef.current.valueAsNumber,
      numNo: noRef.current.valueAsNumber,
    };
    try {
      if (id) {
        await axios.put(`/api/popi-data/${id}`, data);
      } else {
        const { id } =
          (await axios.post<{ popiData: PopiData }>(`/api/surveys/${survey.id}/popi-data`, data))
            .data.popiData;
        setId(id);
      }
      setIsDirty(false);
    } finally {
      setIsLoading(false);
    }
  }

  function validateIsDirty() {
    const isNowDirty =
      yesRef.current.valueAsNumber !== numYes
      || noRef.current.valueAsNumber !== numNo;
    if (isNowDirty !== isDirty) {
      setIsDirty(isNowDirty);
    }
  }

  return (
    <ListGroupItem className="position-relative">
      <Loader isLoading={isLoading} />
      <Row>
        <Label md={{ offset: 3, size: 4 }}>Yes</Label>
        <Label md={4}>No</Label>
      </Row>
      <Row>
        <Label md={3}>{MONTHS[month - 1]} {year}</Label>
        <Col md={4}>
          <Input
            type="number"
            defaultValue={numYes.toString()}
            innerRef={yesRef}
            min={0}
            onChange={validateIsDirty}
          />
        </Col>
        <Col md={4}>
          <Input
            type="number"
            defaultValue={numNo.toString()}
            innerRef={noRef}
            min={0}
            onChange={validateIsDirty}
          />
        </Col>
        <Col md={1}>
          <Button sm color="primary" disabled={isLoading || !isDirty} onClick={save}>
            <FontAwesomeIcon icon={faSave} />
          </Button>
        </Col>
      </Row>
    </ListGroupItem>
  );
};

export default PopiControl;
