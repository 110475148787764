import * as React from 'react';
import ReportTableData from './ReportTableData';
import ReportControl from './ReportControl';
import ReportTableDisplay from './ReportTableDisplay/ReportTableDisplay';

export default class ReportTable extends ReportControl {
  title: string;
  header: string[];
  data: string[][];
  constructor(reportTableData: ReportTableData) {
    super();
    this.title = reportTableData.title;
    this.header = reportTableData.header;
    this.data = reportTableData.data;
  }
  render(key: string | number) {
    return <ReportTableDisplay reportTable={this} />;
  }
}
