import * as React from 'react';
import axios from 'axios';
import Survey from '../../interfaces/Survey';
import Notification from '../../interfaces/Notification';
import Act from '../../interfaces/Act';
import {
  ListGroupItem,
  ListGroup,
  FormGroup,
  Label,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Button,
} from 'reactstrap';
import Loader from '../Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTrash } from '@fortawesome/free-solid-svg-icons';
import Row from 'reactstrap/lib/Row';

interface NotificationCardProps {
  notification?: Notification;
  acts: Act[];
  survey: Survey;
  addNotification?: (notification: Notification) => any;
  removeNotification: (notification: Notification) => any;
}

const NotificationCard: React.FC<NotificationCardProps> =
  ({ acts, notification, survey, addNotification, removeNotification }) => {
    const isNew = !notification;
    const [dropdownIsOpen, setDropdownIsOpen] = React.useState(false);
    const [act, setAct] =
      React.useState<Act | null>(
        notification && acts.find(a => a.id === notification.actId) || null,
      );
    const [isLoading, setIsLoading] = React.useState(false);
    const activeRef = React.useRef<HTMLInputElement>();
    const emailRef = React.useRef<HTMLInputElement>();
    const canSave = !!act;

    async function save() {
      if (isLoading) {
        return;
      }
      setIsLoading(true);
      const data = {
        active: activeRef.current.checked,
        email: emailRef.current.value,
        actId: act.id,
      };
      try {
        if (notification && notification.id) {
          await axios.put(`/api/notifications/${notification.id}`, data);
        } else {
          const notification = await axios
            .post<{ notification: Notification }>(`/api/surveys/${survey.id}/notifications`, data)
            .then(r => r.data.notification);
          addNotification(notification);
          activeRef.current.checked = true;
          emailRef.current.value = '';
          setAct(null);
        }
      } finally {
        setIsLoading(false);
      }
    }
    async function remove() {
      if (isNew || isLoading) {
        return;
      }
      setIsLoading(true);
      await axios.delete(`/api/notifications/${notification.id}`);
      setIsLoading(false);
      removeNotification(notification);
    }
    return (
      <FormGroup className="mb-0 position-relative" >
        <Loader isLoading={isLoading} />
        <Row>
          <Col md={1}>
            <Label>Active</Label>
          </Col>
          <Col md={4}>
            <Label>Email</Label>
          </Col>
          <Col md={4}>
            <Label>Act</Label>
          </Col>
          <Col md={3} />
        </Row>
        <Row>
          <Col md={1}>
            <FormGroup check className="mb-0">
              <Input
                type="checkbox"
                defaultChecked={notification ? notification.active : true}
                innerRef={activeRef}
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <Input
              type="text"
              defaultValue={notification && notification.email || ''}
              innerRef={emailRef}
            />
          </Col>
          <Col md={4}>
            {acts &&
              <Dropdown isOpen={dropdownIsOpen} toggle={() => setDropdownIsOpen(!dropdownIsOpen)}>
                <DropdownToggle caret>
                  {act && act.name || 'Select an act'}
                </DropdownToggle>
                <DropdownMenu>
                  {acts.map(a =>
                    <DropdownItem key={a.id} onClick={() => setAct(a)}>
                      {a.name}
                    </DropdownItem>,
                  )}
                </DropdownMenu>
              </Dropdown>
            }
          </Col>
          <Col md={3}>
            <Button
              disabled={isLoading || !canSave}
              className="mr-1"
              color="primary"
              onClick={save}
            >
              <FontAwesomeIcon icon={faSave} />
            </Button>
            {!isNew &&
              <Button color="danger" disabled={isLoading} onClick={remove}>
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            }
          </Col>
        </Row>
      </FormGroup >
    );
  };

const NotificationsView: React.FC<{ survey: Survey }> = ({ survey }) => {
  const [acts, setActs] = React.useState<Act[] | null>(null);
  const [notifications, setNotifications] = React.useState<Notification[] | null>(null);
  const [isLoading, setIsLoading] = React.useState(true);
  async function refresh() {
    setIsLoading(true);
    const [acts, notifications] = await Promise.all([
      axios
        .get<{ acts: Act[] }>(`/api/surveys/${survey.id}/acts/final`)
        .then(r => r.data.acts),
      axios
        .get<{ notifications: Notification[] }>(`/api/surveys/${survey.id}/notifications`)
        .then(r => r.data.notifications),
    ]);
    setActs(acts);
    setNotifications(notifications);
    setIsLoading(false);
  }
  function addNotification(notification: Notification) {
    setNotifications([...notifications, notification]);
  }
  function removeNotification(notification: Notification) {
    const index = notifications.indexOf(notification);
    if (index === -1) {
      return;
    }
    const newNotifications = [...notifications];
    newNotifications.splice(index, 1);
    setNotifications(newNotifications);
  }
  React.useEffect(() => { refresh(); }, []);
  return (
    <div className="position-relative">
      <Loader isLoading={isLoading} />
      {notifications && acts &&
        <ListGroup flush>
          {notifications.map(n =>
            <ListGroupItem key={n.id}>
              <NotificationCard
                notification={n}
                acts={acts}
                survey={survey}
                removeNotification={removeNotification}
              />
            </ListGroupItem>,
          )}
          <ListGroupItem >
            <NotificationCard
              acts={acts}
              survey={survey}
              addNotification={addNotification}
              removeNotification={removeNotification}
            />
          </ListGroupItem>,
        </ListGroup>
      }
    </div>
  );
};

export default NotificationsView;
