import * as React from 'react';
import {
  Col,
  Table,
} from 'reactstrap';
import ReportTable from '../ReportTable';
import './ReportTableDisplay.css';

interface ReportTableDisplayProps {
  reportTable: ReportTable;
}

export default class ReportTableDisplay extends React.Component
  <
  ReportTableDisplayProps
  >
{
  render() {
    const { header, data, title } = this.props.reportTable;
    return (
      <Col md={12}>
        <div className="bar-graph-title">
          <legend>{title}</legend>
        </div>
        <Table size="sm" className="report-table">
          <thead>
            <tr>
              {header.map((h, i) =>
                <th key={i}>{h}</th>,
              )}
            </tr>
          </thead>
          <tbody>
            {data.map((d, i) =>
              <tr key={i}>
                {d.map((d, i) =>
                  <td
                    className={typeof d === 'number' && d < 3 ? 'text-danger font-weight-bold' : ''}
                    key={i}>
                    {d}
                  </td>,
                )}
              </tr>)}
          </tbody>
        </Table>
      </Col>
    );
  }
}
