import * as React from 'react';
import axios from 'axios';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Row, Col, Alert, Input } from 'reactstrap';
import Loader from './Loader';
import ReportView from '../../common/components/ReportView/ReportView';
import Report from '../interfaces/Report';
import ReportItem from '../interfaces/ReportItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import Button from 'reactstrap/lib/Button';

interface ReportPreviewData {
  report: Report;
  reportItems: ReportItem[];
}

interface ReportPublishResponse {
  url: string;
}

const ReportPreview: React.FC<RouteComponentProps<{ surveyId?: string }>> = ({ match }) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [reportPreviewData, setReportPreviewData] =
    React.useState<ReportPreviewData | null>(null);
  const [publishResponse, setPublishResponse] = React.useState<ReportPublishResponse | null>(null);
  const [isPublishing, setIsPublishing] = React.useState(false);
  const { surveyId } = match.params;

  async function refresh() {
    setIsLoading(true);
    const reportPreviewData = await axios
      .get<ReportPreviewData>(`/api/surveys/${surveyId}/report`)
      .then(r => r.data);
    setReportPreviewData(reportPreviewData);
    setIsLoading(false);
  }

  const publishedUrlRef = React.useRef<HTMLInputElement>();

  function copyPublishedUrl(evt: React.MouseEvent) {
    evt.preventDefault();
    publishedUrlRef.current.select();
    document.execCommand('copy');
  }

  async function publish() {
    setIsPublishing(true);
    const { report } = reportPreviewData;
    try {
      const result = await axios
        .post<ReportPublishResponse>(`/api/reports/${report.key}/publish`);
      setPublishResponse(result.data);
    } catch {
      // ????
    } finally {
      setIsPublishing(false);
    }
  }

  React.useEffect(() => { refresh(); }, []);
  return (
    <Row>
      <Col md={12}>
        <Loader isLoading={isLoading} />
        {
          reportPreviewData
          && <div>
            {!publishResponse &&
              <Alert color="warning">
                <p className="mb-1">
                  <FontAwesomeIcon icon={faInfoCircle} className="mr-1" />
                  <b>This report has not been published yet.</b>
                </p>
                <p className="mb-1">
                  To create a public link to the report, click publish.
                </p>
                <Button disabled={isPublishing} color="success" onClick={publish} >
                  Publish
                </Button>
              </Alert>
            }
            {publishResponse &&
              <Alert color="success">
                <p className="mb-1">
                  <FontAwesomeIcon icon={faCheckCircle} className="mr-1" />
                  <b>Success!</b>
                </p>
                <p className="mb-1">
                  This report has been published and can be viewed at the link below.
                   <a className="ml-1 small" href="#" onClick={copyPublishedUrl}>
                    Copy link to clipboard
                   </a>
                </p>
                <Input readonly value={publishResponse.url} innerRef={publishedUrlRef} />
              </Alert>
            }
            <ReportView
              report={reportPreviewData.report}
              reportItems={reportPreviewData.reportItems}
            />
          </div>
        }
      </Col>
    </Row>
  );
};

export default withRouter(ReportPreview);
