import * as React from 'react';
import classnames from 'classnames';
import { Link, Switch, Route, withRouter, RouteComponentProps } from 'react-router-dom';
import { Label, FormGroup, Nav } from 'reactstrap';

import Survey from '../interfaces/Survey';

import RecipientsList from './RecipientsList';
import SubmittedRecipientsList from './SubmittedRecipientsList';
import SurveyStats from './SurveyStats';
import RNav from './RNav';
import ReportPreview from './ReportPreview';

interface SurveyRecipientsViewProps extends RouteComponentProps<any> {
  survey: Survey;
}

class SurveyRecipientsView extends React.Component<SurveyRecipientsViewProps> {
  render() {
    const { match } = this.props;
    return <div>
      <FormGroup>
        <Nav tabs>
          <RNav path={`${match.url}/awaiting-approval`}>
            Awaiting Approval
            </RNav>
          <RNav path={`${match.url}/queued`}>
            Queued for sending
            </RNav>
          <RNav path={`${match.url}/sent`}>
            Sent
            </RNav>
          <RNav path={`${match.url}/completed`}>
            Completed
          </RNav>
        </Nav>
      </FormGroup>
      <FormGroup>
        <Switch>
          <Route
            path={`${match.path}/awaiting-approval`}
            component={SubmittedRecipientsList}
          />
          <Route
            path={`${match.path}/queued`}
            component={withRouter(() => <RecipientsList type="queued" />)} />
          <Route
            path={`${match.path}/sent`}
            component={withRouter(() => <RecipientsList type="sent" />)} />
          <Route
            path={`${match.path}/completed`}
            component={withRouter(() => <RecipientsList type="completed" />)} />
        </Switch>
      </FormGroup>
    </div >;
  }
}

export default withRouter(SurveyRecipientsView);
