import * as React from 'react';
import Survey from '../interfaces/Survey';
import WebModuleParameter from '../interfaces/WebModuleParameter';
import axios from 'axios';
import { Col, Row, Input, FormGroup, Label, InputGroup, InputGroupAddon, Button } from 'reactstrap';
import WebModuleParameterField from '../interfaces/WebModuleParameterField';
import WebModuleParameterValue from '../interfaces/WebModuleParameterValue';
import classnames from 'classnames';
import Loader from './Loader';

interface ManageWebModuleParametersComponentProps {
  survey: Survey;
}

interface ConfigApiResponse {
  webModuleParameters: WebModuleParameter[];
}

interface ManageWebModuleParametersComponentState {
  isLoading?: boolean;
  errorMessage?: string;
  webModuleParameters?: WebModuleParameter[];
}

interface ConfigComponentState {
  inputValue: string;
  errorMessage?: string;
  isLoading?: boolean;
  isDirty?: boolean;
}

interface ConfigComponentProps {
  config: WebModuleParameter;
  survey: Survey;
}

abstract class ConfigComponent extends React.Component
  <
  ConfigComponentProps,
  ConfigComponentState
  > {
  fieldRef = React.createRef<HTMLInputElement>();
  abstract renderLabel(): JSX.Element;
  constructor(props: ConfigComponentProps) {
    super(props);
    const { value } = props.config;
    const inputValue = value && value.value;
    this.state = { inputValue };
  }
  onInputChange = () => {
    const inputValue = this.fieldRef.current.value;
    this.setState({ inputValue, isDirty: true });
  }
  update = async () => {
    const { value } = this.fieldRef.current;
    const { config, survey } = this.props;
    const fieldName = encodeURIComponent(config.field.name);
    const url = `/api/surveys/${survey.id}/web-module-parameter-values/${fieldName}`;
    this.setState({ isLoading: true });
    try {
      await axios.put(url, { fieldName, value });
      this.setState({ isLoading: false, isDirty: false });
    } catch (err) {
      const errorMessage =
        (err.response
          && err.response.data
          && err.response.data.message)
        || 'An internal error occurred';
      return this.setState({ errorMessage, isLoading: false });
    }
  }
  renderInput() {
    const { config } = this.props;
    const value = config.value && config.value.value;
    const { isDirty, isLoading } = this.state;
    return <InputGroup>
      <Input
        className={classnames({ 'is-valid': isDirty })}
        defaultValue={value}
        disabled={isLoading}
        innerRef={this.fieldRef}
        onChange={this.onInputChange}
      />
      <InputGroupAddon addonType="append">
        <Button
          disabled={!isDirty || isLoading}
          color="success"
          onClick={this.update}
        >Save</Button>
      </InputGroupAddon>
    </InputGroup>;
  }
  render() {
    return <FormGroup row>
      <Col md={4}>
        {this.renderLabel()}
      </Col>
      <Col md={8}>
        {this.renderInput()}
      </Col>
    </FormGroup>;
  }
}

class TextConfigComponent extends ConfigComponent {
  renderLabel() {
    const { config } = this.props;
    return <Label>
      {config.field.displayName}
    </Label>;
  }
}

class ColorConfigComponent extends ConfigComponent {
  renderLabel() {
    const { config } = this.props;
    const { inputValue } = this.state;
    return <Row>
      <Label md={9}>
        {config.field.displayName}
      </Label>
      <Col md={3}>
        {inputValue &&
          <div style={{
            width: '100%',
            height: '100%',
            border: '1px solid black',
            backgroundColor: inputValue,
          }} />
        }
      </Col>
    </Row>;
  }
}

export default class ManageWebModuleParameterComponent extends React.Component
  <
  ManageWebModuleParametersComponentProps,
  ManageWebModuleParametersComponentState
  >
{
  constructor(props: any) {
    super(props);
    this.state = { webModuleParameters: null };
  }
  async componentDidMount() {
    await this.refreshConfiguration();
  }
  refreshConfiguration = async () => {
    const { survey } = this.props;
    this.setState({ isLoading: true });
    try {
      const [
        fieldResponse,
        valueResponse,
      ] = await Promise.all([
        axios.get<{ webModuleParameters: WebModuleParameterField[] }>(
          `/api/surveys/${survey.id}/web-module/fields`,
        ),
        axios.get<{ webModuleParameterValues: WebModuleParameterValue[] }>(
          `/api/surveys/${survey.id}/web-module-parameter-values`,
        ),
      ]);
      const webModuleParameters = fieldResponse.data.webModuleParameters
        .map(field => ({
          field,
          value: valueResponse.data.webModuleParameterValues
            .find(smf => smf.fieldName === field.name) || null,
        }));
      this.setState({ webModuleParameters, isLoading: false });
    } catch (err) {
      console.error(err);
      const errorMessage =
        (err.response
          && err.response.data
          && err.response.data.message)
        || 'An internal error occurred';
      this.setState({ errorMessage, isLoading: false });
    }
  }
  render() {
    const { webModuleParameters, isLoading } = this.state;
    const { survey } = this.props;
    return <div>
      <Loader isLoading={isLoading} />
      {!isLoading && webModuleParameters &&
        <React.Fragment >
          {webModuleParameters
            .map((wmfc) => {
              const wmfcProps = { survey, config: wmfc, key: wmfc.field.id };
              return wmfc.field.type === 'color' && <ColorConfigComponent {...wmfcProps} />
                || wmfc.field.type === 'text' && <TextConfigComponent {...wmfcProps} />;
            })}
        </React.Fragment>
      }
    </div>;
  }
}
