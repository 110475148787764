import * as React from 'react';
import classnames from 'classnames';
import BarGraphData from './BarGraphData';
import BarGraph from './BarGraph';
import { Row, Col } from 'reactstrap';
import ReportTableData from './ReportTableData';
import ReportTable from './ReportTable';
import Report from '../../../portal/interfaces/Report';
import ReportLogo from './ReportLogo/ReportLogo';
import * as _ from 'lodash';
import './ReportView.scss';
import ReportItem from '../../../portal/interfaces/ReportItem';
import TrendLegend from './TrendLegend/TrendLegend';

function formatDate(dateString: string) {
  return new Date(`${dateString}T00:00:00Z`).toLocaleDateString('en-ZA', { timeZone: 'UTC', year: 'numeric', month: 'short', day: 'numeric' });
}

const ReportHeader: React.FC<{ report: Report, sub?: boolean }> = ({ report, sub }) => (
  <div className={classnames(['report-header', { group: sub }])}>
    <ReportLogo
      className="mb-1"
      url="https://vintron-asset.s3-eu-west-1.amazonaws.com/logo/bryte-logo.png" />
    <h3>Survey statistics</h3>
    <h4>
      <span>{formatDate(report.dateFrom)}</span>
      <span> to </span>
      <span>{formatDate(report.dateTo)}</span>
    </h4>
  </div>
);

const PageLegend: React.FC<{ page: number }> = ({ page }) => {
  const height = 39.47; //29.7;
  const margin = 0.25 * 2.54;
  const actualHeight = height - margin * 2;
  const offset = page * actualHeight;
  console.log(actualHeight);
  const top = `calc(${offset}cm - 275px - 15px)`;
  return <TrendLegend className="print-only" style={{ position: 'absolute', top }} />;
};

const ReportView: React.FC<{ report: Report, reportItems: ReportItem[] }> = ({ report, reportItems }) => {
  const allBarGraphData = reportItems.filter(c => c.control === 'bar').map(c => c.data as BarGraphData);
  const controls = reportItems.map((c) => {
    if (c.control === 'bar') {
      return new BarGraph(c.data as BarGraphData, allBarGraphData);
    }
    if (c.control === 'table') {
      return new ReportTable(c.data as ReportTableData);
    }
  });
  const paddedControls = [];
  for (const control of controls) {
    const isFirstOnRow: boolean = paddedControls.length % 2 === 0;
    const isFullWidth = (control as BarGraph).width === 'full' || control instanceof ReportTable;
    if (isFullWidth) {
      const toPush = [control, null];
      paddedControls.push(...(isFirstOnRow ? toPush : toPush.reverse()))
    } else {
      paddedControls.push(control);
    }
  }
  const controlsChunked = _.chunk(paddedControls, 2);
  return (
    <React.Fragment>
      <TrendLegend fixed />
      <PageLegend page={2} />
      <PageLegend page={3} />
      <PageLegend page={4} />
      <div className="report-cover">
        <ReportHeader report={report} />
        <div className="report-footer">
          <ReportLogo
            className="mb-1 vintron-logo"
            footer
            url="https://vintron-asset.s3-eu-west-1.amazonaws.com/logo/vintron-logo.png" />
        </div>
      </div>
      {controlsChunked.map((cc, i) => {
        const shouldSplit = cc.some(c => c instanceof ReportTable);
        return (
          <Row className={classnames("report-page", { 'report-page-isolated': shouldSplit })}>
            {cc.map((c, i) => c?.render(i))}
          </Row>
        );
      })}
    </React.Fragment>
  );
};

export default ReportView;