import * as React from 'react';
import Survey from '../interfaces/Survey';
import { FormGroup, Button, Label, Input, Col } from 'reactstrap';
import CloneSurveyModal from './CloneSurveyModal';
import DeleteSurveyModal from './DeleteSurveyModal';
import { Interval, IntervalUnit } from '../interfaces/Interval';
import axios from 'axios';

interface SurveySettingsProps {
  survey: Survey;
  onSurveyUpdated: () => void;
  onSurveyDeleted: () => void;
}

interface SurveySettingsState {
  isLoading?: boolean;
  cloneModalIsOpen?: boolean;
  deleteModalIsOpen?: boolean;
}

const DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const INTERVAL_UNITS = ['minutes', 'hours', 'days', 'months'];

function getIntervalUnit(interval: Interval): IntervalUnit {
  for (const unit of INTERVAL_UNITS) {
    const lcu = unit.toLowerCase() as IntervalUnit;
    if (interval.hasOwnProperty(lcu)) {
      return lcu;
    }
  }
  return null;
}

export default class SurveySettings
  extends React.Component<SurveySettingsProps, SurveySettingsState>
{
  nameRef = React.createRef<HTMLInputElement>();
  sendDelayNumberRef = React.createRef<HTMLInputElement>();
  sendDelayUnitRef = React.createRef<HTMLInputElement>();
  dayRefs = DAYS.map(d => React.createRef<HTMLInputElement>());
  constructor(props: any) {
    super(props);
    this.state = {};
  }
  save = async () => {
    const { survey, onSurveyUpdated } = this.props;
    const send_delay = {
      [this.sendDelayUnitRef.current.value.toLowerCase()]:
        this.sendDelayNumberRef.current.value,
    };
    const send_on_days = this.dayRefs
      .reduce((pv, dr, i) => dr.current.checked ? [...pv, i] : [...pv], []);
    const data = {
      send_delay,
      send_on_days,
      name: this.nameRef.current.value,
    };
    this.setState({ isLoading: true });
    try {
      await axios.put<{ survey: Survey }>(`/api/surveys/${survey.id}`, data);
      this.setState({ isLoading: false });
      onSurveyUpdated();
    } catch (err) {
      this.setState({ isLoading: false });
      console.error(err);
    }
  }
  surveyWasDeleted = async () => {
    this.props.onSurveyDeleted();
  }
  render() {
    const { survey } = this.props;
    const intervalUnit = getIntervalUnit(survey.send_delay) || 'hours';
    const intervalNumber = survey.send_delay[intervalUnit] || 0;
    const { isLoading, cloneModalIsOpen, deleteModalIsOpen } = this.state;
    const disabled = isLoading || !survey.canDelete;
    return <div>
      <CloneSurveyModal
        survey={survey}
        isOpen={cloneModalIsOpen}
        wasClosed={() => this.setState({ cloneModalIsOpen: false })}
        wasSuccessful={() => { }} />
      <DeleteSurveyModal
        survey={survey}
        isOpen={deleteModalIsOpen}
        wasClosed={() => this.setState({ deleteModalIsOpen: false })}
        wasSuccessful={this.surveyWasDeleted} />
      {!survey.canDelete && <p className="text-danger">This survey is locked.</p>}
      <FormGroup row>
        <Label md={3}>Name</Label>
        <Col md={9}>
          <Input
            type="text"
            disabled={disabled}
            innerRef={this.nameRef}
            defaultValue={survey.name}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label md={3}>Send on</Label>
        <Col md={9}>
          {DAYS.map((day, i) => {
            return <FormGroup check>
              <Label check>
                <Input
                  disabled={disabled}
                  innerRef={this.dayRefs[i]}
                  type="checkbox"
                  defaultChecked={survey.send_on_days.indexOf(i) > -1} />
                {day}
              </Label>
            </FormGroup>;
          })}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label md={3}>Send delay</Label>
        <Col md={3}>
          <Input
            disabled={disabled}
            type="number"
            min={0}
            innerRef={this.sendDelayNumberRef}
            defaultValue={intervalNumber.toString()} />
        </Col>
        <Col md={3}>
          <Input
            disabled={disabled}
            type="select"
            innerRef={this.sendDelayUnitRef}
            defaultValue={intervalUnit}
          >
            {INTERVAL_UNITS.map(i => (<option>{i}</option>))}
          </Input>
        </Col>
      </FormGroup>
      <FormGroup>
        <Button
          color="success"
          disabled={disabled}
          onClick={this.save}>
          Save changes
        </Button>
      </FormGroup>
      <hr />
      <FormGroup>
        <Button
          color="primary"
          disabled={isLoading}
          onClick={() => this.setState({ cloneModalIsOpen: true })}>
          Clone this survey
        </Button>
        {survey.canDelete &&
          <Button color="danger"
            className="ml-2"
            onClick={() => this.setState({ deleteModalIsOpen: true })}>
            Delete this survey
          </Button>
        }
      </FormGroup>
    </div>;
  }
}
