import * as React from 'react';
import Survey from '../interfaces/Survey';
import axios, { AxiosRequestConfig } from 'axios';
import Asset from '../interfaces/Asset';
import ListGroupItem from 'reactstrap/lib/ListGroupItem';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import FormGroup from 'reactstrap/lib/FormGroup';
import Button from 'reactstrap/lib/Button';
import Label from 'reactstrap/lib/Label';
import Input from 'reactstrap/lib/Input';
import { InputGroup, InputGroupAddon, Progress } from 'reactstrap';
import classnames from 'classnames';
import * as filesize from 'filesize';
import Loader from './Loader';

const MAX_UPLOAD_SIZE = 1024 * 1024 * 3; // 3 MB
const MAX_UPLOAD_DESC = filesize(MAX_UPLOAD_SIZE);
const ACCEPTED_FILE_TYPES = '.jpg, .png, .jpeg';

interface ManageAssetsComponentProps {
  survey: Survey;
}

interface ManageAssetsComponentState {
  uploadProgress?: number;
  isLoading?: boolean;
  assets: Asset[];
  errorMessage?: string;
  isUploading?: boolean;
  uploadError?: string;
  uploadMessage?: string;
}

interface AssetsResponse {
  assets: Asset[];
}

interface AssetListItemProps {
  asset: Asset;
  onDelete?: (asset: Asset) => void;
  onUpdate?: (asset: Asset, oldAsset?: Asset) => void;
}

interface AssetListItemState {
  isDirty?: boolean;
  isLoading?: boolean;
  errorMessage?: string;
  isUploading?: boolean;
  uploadProgress?: number;
  uploadMessage?: string;
}

class AssetListItem extends React.Component<AssetListItemProps, AssetListItemState> {
  nameRef = React.createRef<HTMLInputElement>();
  constructor(props: any) {
    super(props);
    this.state = { isDirty: false };
  }
  delete = async () => {
    this.setState({ isLoading: true });
    const { asset, onDelete } = this.props;
    try {
      await axios.delete(`/api/assets/${asset.id}`);
      if (onDelete) {
        onDelete(asset);
      }
      this.setState({ isLoading: false });
    } catch (err) {
      this.setState({ isLoading: false });
      console.error(err);
    }
  }
  onChange = () => {
    this.setState({ isDirty: true });
  }
  update = async () => {
    const { asset, onUpdate } = this.props;
    const name = this.nameRef.current.value;
    this.setState({ isLoading: true });
    try {
      await axios.put(`/api/assets/${asset.id}`, { name });
      if (onUpdate) {
        onUpdate(asset);
      }
      this.setState({ isLoading: false, isDirty: false });
    } catch (err) {
      this.setState({ isLoading: false });
      console.error(err);
    }
  }
  onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { asset, onUpdate } = this.props;
    const file = e.target.files[0];
    e.target.value = null;
    const imageName = file.name;
    const imageSize = filesize(file.size);
    const imageDescription = `${imageName} (${imageSize})`;
    if (file.size > MAX_UPLOAD_SIZE) {
      const errorMessage = `${imageDescription} exceeds the size limit (${MAX_UPLOAD_DESC})`;
      return this.setState({ errorMessage, isUploading: false, uploadProgress: 0 });
    }
    this.setState({
      isUploading: true,
      uploadMessage: `Uploading ${imageDescription}...`,
      uploadProgress: 0,
      errorMessage: null,
    });
    const fd = new FormData();
    // const name = imageName.substring(0, imageName.lastIndexOf('.')) || imageName;
    // fd.append('name', name);
    fd.append('image', file);
    const options: AxiosRequestConfig = {
      onUploadProgress: (e) => {
        this.setState({ uploadProgress: e.loaded / e.total * 100 });
      },
    };
    try {
      const newAsset = (await axios.put<{ asset: Asset }>(
        `/api/assets/${asset.id}`,
        fd,
        options,
      )).data.asset;
      this.setState(
        { isUploading: false, uploadProgress: 0 },
        () => onUpdate && onUpdate(newAsset, asset),
      );
      // await this.refreshAssets();
    } catch (err) {
      console.error(err.response);
      const errorMessage =
        (err.response
          && err.response.data
          && err.response.data.message)
        || 'An internal error occurred';
      return this.setState({ errorMessage, isUploading: false });
    }
  }
  render() {
    const { asset } = this.props;
    const {
      isDirty, isLoading, isUploading, uploadMessage, uploadProgress, errorMessage,
    } = this.state;
    return <ListGroupItem>
      <Row>
        <Col md={4}>
          <div className="asset-image-container">
            <img className="asset-image" src={asset.url} />
          </div>
        </Col>
        <Col md={8}>
          <FormGroup row>
            <Label sm={2}>Name</Label>
            <Col sm={10}>
              <InputGroup>
                <Input
                  onChange={this.onChange}
                  type="text"
                  defaultValue={asset.name}
                  innerRef={this.nameRef}
                  placeholder={name}
                  className={classnames({ 'is-valid': isDirty })}
                  disabled={isLoading} />
                <InputGroupAddon addonType="append">
                  <Button
                    color="success"
                    disabled={!isDirty || isLoading || isUploading}
                    onClick={this.update}>
                    Save
                  </Button>
                </InputGroupAddon>
              </InputGroup>
            </Col>
          </FormGroup>
          <FormGroup className="mb-0">
            <Label
              className="btn btn-primary file-container mb-0 mr-2"
              disabled={isLoading || isUploading}>
              Upload
            <input
                id="file"
                type="file"
                accept={ACCEPTED_FILE_TYPES}
                onChange={this.onFileChange}
                disabled={isLoading || isUploading}
              />
            </Label>
            <Button
              color="danger"
              onClick={this.delete}
              disabled={isLoading || isUploading}
            >
              Delete
            </Button>
          </FormGroup>
          {isUploading &&
            <FormGroup className="mb-0 mt-2">
              <p className="text-success">
                {uploadMessage}
              </p>
              <Progress value={uploadProgress} />
            </FormGroup>
          }
          {errorMessage &&
            <p className="text-danger mt-2 mb-0">
              {errorMessage}
            </p>
          }
        </Col>
      </Row>
    </ListGroupItem>;
  }
}

export default class ManageAssetsComponent extends React.Component
  <
  ManageAssetsComponentProps,
  ManageAssetsComponentState
  >
{
  constructor(props: any) {
    super(props);
    this.state = {
      isLoading: true,
      assets: [] as Asset[],
    };
  }
  async componentDidMount() {
    await this.refreshAssets();
  }
  refreshAssets = async () => {
    const { survey } = this.props;
    this.setState({ isLoading: true });
    try {
      const { assets }
        = (await axios.get<AssetsResponse>(`/api/surveys/${survey.id}/assets`)).data;
      this.setState({ assets, isLoading: false });
    } catch (err) {
      console.error(err);
      const errorMessage =
        (err.response
          && err.response.data
          && err.response.data.message)
        || 'An internal error occurred';
      this.setState({ errorMessage, isLoading: false });
    }
  }
  assetWasDeleted = async (asset: Asset) => {
    const existingAssets = this.state.assets;
    const index = existingAssets.indexOf(asset);
    if (index > -1) {
      const assets = [...existingAssets];
      assets.splice(assets.indexOf(asset), 1);
      this.setState({ assets });
    }
  }
  assetWasAdded = async (asset: Asset) => {
    const existingAssets = this.state.assets;
    const index = existingAssets.indexOf(asset);
    if (index === -1) {
      this.setState({ assets: [...existingAssets, asset] });
    }
  }
  assetWasUpdated = async (asset: Asset, oldAsset: Asset) => {
    if (oldAsset) {
      const assets = [...this.state.assets];
      const index = assets.indexOf(oldAsset);
      if (index > -1) {
        assets[index] = asset;
        return this.setState({ assets });
      }
    }
    // await this.refreshAssets();
  }
  fileChanged = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { survey } = this.props;
    e.preventDefault();
    const file = e.target.files[0];
    e.target.value = null;
    const imageName = file.name;
    const imageSize = filesize(file.size);
    const imageDescription = `${imageName} (${imageSize})`;
    if (file.size > MAX_UPLOAD_SIZE) {
      const uploadError = `${imageDescription} exceeds the size limit (${MAX_UPLOAD_DESC})`;
      return this.setState({ uploadError, isUploading: false, uploadProgress: 0 });
    }
    this.setState({
      isUploading: true,
      uploadMessage: `Uploading ${imageDescription}...`,
      uploadProgress: 0,
      uploadError: null,
    });
    const fd = new FormData();
    const name = imageName.substring(0, imageName.lastIndexOf('.')) || imageName;
    fd.append('name', name);
    fd.append('image', file);
    const options: AxiosRequestConfig = {
      onUploadProgress: (e) => {
        this.setState({ uploadProgress: e.loaded / e.total * 100 });
      },
    };
    try {
      const newAsset = (await axios.post<{ asset: Asset }>(
        `/api/surveys/${survey.id}/assets`,
        fd,
        options,
      )).data.asset;
      this.setState({ isUploading: false, uploadProgress: 0 }, () => this.assetWasAdded(newAsset));
    } catch (err) {
      console.error(err.response);
      const uploadError =
        (err.response
          && err.response.data
          && err.response.data.message)
        || 'An internal error occurred';
      return this.setState({ uploadError, isUploading: false });
    }
  }
  render() {
    const { assets, isLoading, isUploading, uploadProgress, uploadError, uploadMessage }
      = this.state;
    return <div>
      <Loader isLoading={isLoading} />
      {!isLoading && assets &&
        <React.Fragment>
          <FormGroup>
            {assets.map(asset =>
              <AssetListItem
                key={asset.id}
                asset={asset}
                onDelete={this.assetWasDeleted}
                onUpdate={this.assetWasUpdated}
              />,
            )}
            <ListGroupItem>
              {!isUploading &&
                <FormGroup className="mb-0">
                  <Label
                    className="btn btn-primary file-container mb-0"
                    disabled={isUploading}>
                    Upload a new asset
                  <input
                      id="file"
                      type="file"
                      accept={ACCEPTED_FILE_TYPES}
                      onChange={this.fileChanged}
                      disabled={isUploading} />
                  </Label>
                </FormGroup>
              }
              {isUploading &&
                <FormGroup className="mb-0">
                  <p className="text-success">
                    {uploadMessage}
                  </p>
                  <Progress value={uploadProgress} />
                </FormGroup>
              }
              {uploadError &&
                <p className="text-danger mt-2 mb-0">
                  {uploadError}
                </p>
              }
            </ListGroupItem>
          </FormGroup>
        </React.Fragment>
      }
    </div>;
  }
}
