import * as React from 'react';
import {
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from 'reactstrap';

interface DropDownProps<T> {
  items: T[];
  selectedItem?: T;
  onItemChanged: (item: T) => void;
  getName: (item: T) => string;
  defaultLabel?: string;
}

interface DropDownState {
  isOpen: boolean;
}

export default class ItemDropDown<T> extends React.Component<DropDownProps<T>, DropDownState> {
  constructor(props: any) {
    super(props);
    this.state = { isOpen: false };
  }
  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  }
  render() {
    const { isOpen } = this.state;
    const { items, onItemChanged, selectedItem, getName, defaultLabel } = this.props;
    return <Dropdown isOpen={isOpen} toggle={this.toggle} style={{ width: '100%' }}>
      <DropdownToggle style={{ width: '100%' }}>
        <div className="item-dropdown-caret-container">
          <span className="dropdown-toggle" />
        </div>
        <div className="item-dropdown-label-container">
          {selectedItem && getName(selectedItem) || defaultLabel || 'None'}
        </div>
      </DropdownToggle>
      <DropdownMenu>
        {items.map((item, index) =>
          <DropdownItem key={index}
            onClick={(e: React.MouseEvent<HTMLElement>) => onItemChanged(item)}>
            {getName(item)}
          </DropdownItem>,
        )}
      </DropdownMenu>
    </Dropdown>;
  }
}
